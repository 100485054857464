import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-html-preview',
  standalone: true,
  imports: [],
  templateUrl: './html-preview.component.html',
  styleUrl: './html-preview.component.scss'
})

export class HtmlPreviewComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string, htmlString: string },
    public dialogRef: MatDialogRef<HtmlPreviewComponent>,
    private sanitizer: DomSanitizer
  ) {

    console.log("i am called -------->>>>", this.data.htmlString)

  }

  get cleanHtml(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.data.htmlString);
  }

  objectKeys(obj: { [key: string]: string }): string[] {
    return Object.keys(obj);
  }
}
